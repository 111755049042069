/* App.css */
.layout {
    min-height: 100vh;
}

.header {
    background-color: #001529;
    padding: 0;
    display: flex;
    align-items: center;
}

.logo {
    width: 120px;
    height: 31px;
    background: rgba(255, 255, 255, 0.2);
    margin: 16px 28px 16px 0;
    float: left;
}

.site-layout-content {
    background: #fff;
    padding: 24px;
    min-height: 280px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

h1 {
    font-family: 'Arial', sans-serif;
    font-size: 24px;
    color: #001529;
    text-align: center;
}

footer {
    background: #001529;
    color: #fff;
}