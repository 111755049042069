.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f2f5;
}

.login-form {
    width: 400px;
    padding: 24px;
    background: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.login-form .ant-form-item {
    margin-bottom: 16px;
}

.login-button {
    width: 100%;
}

.login-link {
    text-align: center;
    display: flex;
    justify-content: center;
}